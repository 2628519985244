import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "./components/public/404";
import About from "./components/public/about";
// import Contact from "./components/public/contact";
import Footer from "./components/shared/footer";
import Home from "./components/public/home/index";
// import Login from "./components/public/login";
import Navbar from "./components/shared/navbar";
import ScrollToTop from "./components/shared/scrolltop";
import Projects from "./pages/public/projects";
// import Reset from "./components/public/reset";
function App() {
  return (
    <Router>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Projects" element={<Projects/>} />
        {/*<Route path="/Contact" element={<Contact />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Reset" element={<Reset />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
