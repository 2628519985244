import React from "react";

const Brand = () => {
  return (
    <section className="Brand">
      <div className="Brand_Text">
        <div className="container_brand">
          <p className="Title_Text black">Transform Your Brand</p>
          <p className="Text light_black">
            I am Full-service creative developer specialized in helping brand
            grow faster. Helping you get your business online, develop an
            attractive website which will help you attract clients through
            compelling visuals that does most of the marketing for you.
          </p>
        </div>
      </div>
      <div className="Brand_Logo"></div>
    </section>
  );
};

export default Brand;
