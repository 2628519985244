import html from "../../../assests/image/icons8-html-5-240.png";
import css from "../../../assests/image/icons8-css3-240.png";
import js from "../../../assests/image/icons8-javascript-240.png";
import react from "../../../assests/image/icons8-react-256.png";
import angular from "../../../assests/image/icons8-angular-a-typescript-based-open-source-web-application-framework-96.png";
import firebase from "../../../assests/image/icons8-firebase-240.png";
import amazon from "../../../assests/image/icons8-amazon-web-services-240.png";
import figma from "../../../assests/image/icons8-figma-240.png";
import jwt from "../../../assests/image/icons8-json-web-token-240.png";
// import nodejs from "../../../assests/image/icons8-nodejs-240.png";
import mysql from "../../../assests/image/icons8-mysql-logo-240.png";
import django from "../../../assests/image/icons8-django-240.png";
import dotnetcore from "../../../assests/image/dotnetcore.png";
const About2 = () => {
  return (
    <section className="About_Section scroll_obs ">
      <div className="About_Section__grid ">
        <p className="Text light_black About_Section__do">What i do?</p>
        <div className="Fancy-grid">
          <span className="Fancy-grid__number light_black">/1</span>
          <p className="Fancy-grid__title black">Develop</p>
          <span className="Fancy-grid__arrow light_black">&#8594;</span>
          <img className="Fancy-grid__image" src={html} alt="html" />
          <img className="Fancy-grid__image" src={css} alt="css" />
          <img className="Fancy-grid__image" src={js} alt="js" />
          <img className="Fancy-grid__image" src={react} alt="react" />
          <img className="Fancy-grid__image" src={angular} alt="angular" />
        </div>
        <div className="Fancy-grid Fancy-grid__large  Fancy-grid__up">
          <span className="Fancy-grid__number light_black">/2</span>
          <p className="Fancy-grid__title black">Hosting</p>
          <span className="Fancy-grid__arrow light_black">&#8594;</span>
          <img
            className="Fancy-grid__image Fancy-grid__image__overide2"
            src={amazon}
            alt="aws"
          />
          <img
            className="Fancy-grid__image Fancy-grid__image__overide2"
            src={firebase}
            alt="firebase"
          />
        </div>
        <div className="Fancy-grid  Fancy-grid__large">
          <span className="Fancy-grid__number light_black">/3</span>
          <p className="Fancy-grid__title black">Design</p>
          <span className="Fancy-grid__arrow light_black">&#8594;</span>
          <img className="Fancy-grid__image" src={figma} alt="figma" />
        </div>
        <div className="Fancy-grid">
          <span className="Fancy-grid__number light_black">/4</span>
          <p className="Fancy-grid__title black">Api</p>
          <span className="Fancy-grid__arrow light_black">&#8594;</span>
          <img
            className="Fancy-grid__image Fancy-grid__image__override3"
            src={jwt}
            alt="jwt"
          />
          {/* <img
            className="Fancy-grid__image Fancy-grid__image__override3"
            src={nodejs}
            alt="nodejs"
          /> */}
          <img
            className="Fancy-grid__image Fancy-grid__image__override3"
            src={dotnetcore}
            alt="dotnetcore"
          />
          <img
            className="Fancy-grid__image Fancy-grid__image__override3"
            src={mysql}
            alt="mysql"
          />
          <img className="Fancy-grid__image" src={django} alt="django" />
        </div>
      </div>
    </section>
  );
};

export default About2;
