import React from "react";
import { NavLink, Link } from "react-router-dom";
import brand_image from "../../assests/image/brand_ico.png";
const Navbar = () => {
  React.useEffect(() => {
    const hamburger = document.querySelector(".hamburger");
    const navbar_mobile = document.querySelector(".brand_links_mobile");
    hamburger.addEventListener("click", () => {
      hamburger.innerHTML === "menu" ? toogle("open") : toogle("close");
      navbar_mobile.classList.toggle("show_navbar");
    });
    const toogle = (option) => {
      if (option === "open") {
        hamburger.innerHTML = "menu_open";
        hamburger.setAttribute("data-toggle", "open");
        navbar_mobile.setAttribute("aria-hidden", "true");
      } else if (option === "close") {
        hamburger.innerHTML = "menu";
        hamburger.setAttribute("data-toggle", "close");
        navbar_mobile.setAttribute("aria-hidden", "false");
      }
    };
    /*const option = {};
    const brand = document.querySelector(`[alt="developer"]`);
    if (brand) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            const navabar = document.querySelector(".primary-navbar");
            navabar.classList.add("navbar_scrolled");
          }
        });
      }, option);
      observer.observe(brand);
    }*/
  }, []);
  return (
    <nav className="primary-navbar" aria-label="navbar" role="navigation">
      <div className="brand" aria-label="brand logo">
        <img src={brand_image} alt="brand_image" className="brand_image" aria-label="brand_image" />

        <Link
          className="brand_name"
          exact="true"
          to="/"
          aria-label="Vignesh Chari"
        >
          Vignesh Chari
        </Link>
      </div>
      <span data-toggle="open" className="material-icons hamburger">
        menu
      </span>
      <div className="brand_links">
        <NavLink to="/">Home</NavLink>
        <NavLink to="/About">About</NavLink>
        <NavLink to="/Projects">Projects</NavLink>
        {/* //<NavLink to="/Contact">Contact Us</NavLink>
        //<NavLink to="/Login">Login</NavLink> */}
      </div>
      <div className="brand_links_mobile" aria-hidden="true">
        <div className="brand_links_mobile_container">
          <NavLink to="/">Home</NavLink>
          <NavLink to="/About">About</NavLink>
          
          <NavLink to="/Projects">Projects</NavLink>
          {/* //<NavLink to="/Contact">Contact Us</NavLink>
          //<NavLink to="/Login">Login</NavLink> */}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
