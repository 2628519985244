import React from "react";
import Business from "../../../assests/image/31ddeaf5-95cd-4166-a787-e7f69bcc1587.png";
import Business_Online from "../../../assests/image/2126bea5-15c5-4ad8-99ee-3f88eed5af6d.png";
import Business_Cost from "../../../assests/image/9f85e289-7090-47bf-b23c-24b30ae41dc1.png";
import Business_Avalibility from "../../../assests/image/0caded4f-3124-40e1-bac6-401b85d253b8.png";
import Business_Prodcut from "../../../assests/image/1621607071725_Home-hero-image-1578896855101.png";
import Business_Increase from "../../../assests/image/b8ab141c-f981-4b4d-a884-ec26bcf3ecba.png";
const WhyUs = [
  {
    title: "Improving Your Business’ Reputation",
    image: Business,
    text: " Reputation is critical to a business. A business that acts in an unethical way suffers on many levels including financial (fines,loss of revenue), valuation decreases, branding (distrust) andpublic relations. It means that managing your company’s reputationespecially online has never been so important. Trust is the cornerstone of a successful business and once a brand loses trust with its customers, it can take years to recover if ever.",
  },
  {
    title: "Improving Your Business’ Image",
    image: Business_Online,
    text: " This alone should be enough reason to have a updated or new website and have an online presence. In business, image is everything. If your business image isn’t right, you’re not going to be getting the triumphant results that other businesses will be getting. It doesn’t matter how great your product or service is. The image you present to your audience will make the biggest difference in how well you do.Managing your company’s reputation especially online has never been so important",
  },
  {
    title: "Very Low Start-up Costs",
    image: Business_Cost,
    text: "Starting out online means very low startup costs. You have no buildings to construct, no vehicles to buy and few (if any) staff to hire.Simply build your site, put your products on it, and then market it to get website leads.. If you are already selling offline then the transition can be very smooth. You can continue selling the same products that you know and have a good supply of.By putting your business online you’re be accessing a new source of customers.You might think this is too technical for you… The reality is you can outsource a lot of the technical stuff and even things such as social media marketing",
  },
  {
    title: "24/7 365 Hour Availability",
    image: Business_Avalibility,
    text: "A key benefit to having your business online is that your clients and prospects can read about your products and place orders at any time — day or night. On regular business days or holidays. Imagine what being open 3-4 times longer could do for your business.Even if you have a traditional offline services type business you can generate leads and inquiries while you are closed. Then follow up with those prospects and customers once you open the next day.Or have a PA in different parts of the world that can follow up prospects and customers 24/7.",
  },
  {
    title: "Make it Easier to Showcase Your Products and Services",
    image: Business_Prodcut,
    text: "The Internet gives businesses an effective platform for showcasing what they have to offer. Whether it’s a portfolio and testimonials from clients on a website, or an album on a Facebook page with photos of your newest products, it has never been easier to let the world know what you have to offer.With a few simple clicks, your customers can see what you’re all about. They can even do this outside of business hours! An online presence is an extension of your brand that never sleeps.",
  },
  {
    title: "Increase Business Responsiveness",
    image: Business_Increase,
    text: "The internet allows you to deliver your proposal, purchase order, order confirmation quickly—in many cases instantly—to your clients. Online stores will process orders and confirm them to the client. In the olden days, purchase orders were called in, mailed or dropped off.Depending on the workload of the sales staff, it could have taken hours, or even days to process the order. With a competent online store application, you can automatically track inventory, sales numbers, outstanding orders – everything. Faster response time means happier clients and less administrative work for you.",
  },
];

const Whyus = () => {
  return (
    <section className="Why_Us container_restrict">
      <div className="Why_Us__Intro">
        <p className="Title_Text">
          solid reasons why businesses need a website
        </p>
        <p className="Text">Manage, Promote and Grow Your Business Online</p>
      </div>

      {WhyUs.map((why) => {
        return (
          <div className="Why_Us__Wrap" key={why.title}>
            <div className="Why_Us_Contaiiner" key={why.title}>
              <div className="Why_Us_Image">
                <img
                  data-src={why.image}
                  alt={why.title}
                  aria-label={why.title}
                  className="Why_Us_Images"
                />
              </div>
              <div className="Why_Us_Text">
                <p className="SubHeading_Text white_text">{why.title}</p>
                <p className="Sub_Text Text">{why.text}</p>
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};
export default Whyus;
