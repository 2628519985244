import React from "react";
import Business from "../../../assests/image/undraw_proud_coder_7ain.svg";
import Business_Online from "../../../assests/image/undraw_transfer_money_rywa.svg";
import Business_Cost from "../../../assests/image/undraw_developer_activity_re_39tg.svg";
import Business_Avalibility from "../../../assests/image/undraw_in_progress_re_m1l6.svg";
import Business_Prodcut from "../../../assests/image/undraw_synchronize_re_4irq.svg";
import Business_Increase from "../../../assests/image/undraw_version_control_re_mg66.svg";
import blur from "../../../assests/image/dark-blur-blurred-gradient.jpg";
const WhyUs = [
  {
    title: "True Value",
    image: Business,

    text: "One important factor when choosing a Website design or Web development company is the value you get for your dollar.RootedXpert gives you more than you actually pay for, it's our way of doing business. Happy customers are our most valuable asset.",
  },
  {
    title: "100% Money back Guarantee",
    image: Business_Online,
    text: "If for any reason you are not completely satisfied with the first draft of your website, just notify us and we will process you a full refund",
  },
  {
    title: "100% Satisfaction Guarantee",
    image: Business_Cost,
    text: "Unlimited revisions until you are 100% Satisfied with the results.",
  },
  {
    title: "Powerful, proven website design & web development",
    image: Business_Avalibility,
    text: "RootedXpert has been developing websites since 2019. We are a leading website design and web development company that will be able to provide you with advanced functionality and features. We can help you automate your business processes, convert all your data into a common format, and make sure you're getting what you need from your technology. RootedXpert has provided the technical `brains` behind many SUCCESSFUL, revenue-generating Internet and software companies.",
  },
  {
    title: "Complete Website Design, Web Development & Web Hosting Solutions",
    image: Business_Prodcut,
    text: "Everything is taken care of for you. We provide you a one-stop website design, web development and web hosting solution, which will enable you to have a successful online presence without going through multiple vendors.",
  },
  {
    title: "Full Dedication to Your Online Presence",
    image: Business_Increase,
    text: "Our online efforts are concentrated on serving our customers' effectively, which is why we put an accent on quality customer support available to you round the clock. Not only are our well-trained support people at your disposal 24/7, they are also keen on responding to your requirements almost immediately.We will be there to help you every step of the way, today, tomorrow and for years to come.",
  },
];
const Reasons = () => {
  return (
    <section className="Why_Us container_restrict">
      <div className="Why_Us__Intro">
        <p className="Title_Text ">Teams that Inspire</p>
        <p className="Text">
          Our people are cticial to our success. Our cutlture of inclusion and
          diversity is both foundational to who we are and how we operate and
          vital to the durability of our buisness, the communities we serve, and
          the innovative solutions we create for customers.
        </p>
      </div>

      {WhyUs.map((why) => {
        return (
          <div className="Why_Us__Wrap" key={why.title}>
            <div className="Why_Us_Contaiiner height70 " key={why.title}>
              <div className="Why_Us_Image">
                <img
                  src={blur}
                  data-src={why.image}
                  alt={why.title}
                  aria-label={why.title}
                  className="Why_Us_Images"
                />
              </div>
              <div className="Why_Us_Text">
                <p className="SubHeading_Text white_text">{why.title}</p>
                <p className="Sub_Text Text">{why.text}</p>
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default Reasons;
