import React from "react";
import { NavLink } from "react-router-dom";
import Github_logo from "../../assests/image/icons8-github.svg";
const Footer = () => {
  return (
    <footer className="Brand_Footer">
      <p className="Title_Text white_text center">
        Vignesh Chari
      </p>
      <div className="Footer_links">
        <NavLink to="/" className="white_text">
          Home
        </NavLink>
        <NavLink to="/About" className="white_text">
          About
        </NavLink>
        <NavLink to="/Projects" className="white_text">
           Projects
         </NavLink>
         {/* <NavLink to="/Contact" className="white_text">
          Contact Us
        </NavLink>
        <NavLink to="/Login" className="white_text">
          login
        </NavLink> */}
      </div>
      <div className="Welcome_Social">
        <a
          href="https://github.com/Vignesh960"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Github_logo} alt="github" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
