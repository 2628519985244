import React from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);
  React.useEffect(() => {
    const option = {
      rootMargin: "-20%",
    };
    const scrollOb = document.querySelectorAll(".scroll_obs");
    const tohide = document.querySelector(".scroll_to_top");
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          tohide.setAttribute("aria-hidden", "true");
        } else {
          tohide.setAttribute("aria-hidden", "false");
        }
      });
    }, option);
    scrollOb.forEach((scroll) => {
      observer.observe(scroll);
    });
  }, []);
  return (
    <div
      className="scroll_to_top"
      aria-hidden="true"
      title="Scroll to top"
      role="link"
    >
      <span
        className="material-icons  scroll_to_top__icon"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        expand_less
      </span>
    </div>
  );
};

export default ScrollToTop;
