const scroll_to_top_logic = (margin) => {
  const option = {
    rootMargin: margin || "-20%",
  };
  const scrollOb = document.querySelectorAll(".scroll_obs");
  const tohide = document.querySelector(".scroll_to_top");
  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        tohide.setAttribute("aria-hidden", "true");
      } else {
        tohide.setAttribute("aria-hidden", "false");
      }
    });
  }, option);
  scrollOb.forEach((scroll) => {
    observer.observe(scroll);
  });
};

export default scroll_to_top_logic;
