import React from "react";
import { Link } from "react-router-dom";
import notFound from "../../assests/image/undraw_taken_re_yn20.svg";
import scroll_to_top_logic from "../shared/scrolltotop_logic";
const NotFound = () => {
  React.useEffect(() => {
    scroll_to_top_logic("0px");
  }, []);
  return (
    <>
      <div className="scroll_obs"></div>
      <div
        className="container_404 "
        aria-live="assertive"
        role="alert"
        tabIndex={0}
      >
        <img
          src={notFound}
          alt="meditation"
          aria-label="meditation"
          className="Image_404"
        />
        <p className="Title_Text" aria-label="Something's wrong here.">
          <span> something's wrong here.</span>
        </p>
        <p className="Text description" aria-label="page not found description">
          this is a 404 error, which means you've clicked on a bad link or
          entered an invalid URL. Navigate to
          <Link to="/"> home</Link>
        </p>
      </div>
    </>
  );
};
export default NotFound;
