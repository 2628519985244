import React from "react";

export const Images = () => {
  return (
    <section className="Images_container">
      <div className="Block-1">
        <div className="Bottle_Image"></div>
        <div className="Orange_Image"></div>
      </div>
      <div className="Block-2">
        <div className="Icecream_Image"></div>
        <div className="Sugar_Image"></div>
      </div>
    </section>
  );
};
