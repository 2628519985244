import React from "react";
import scroll_to_top_logic from "../../shared/scrolltotop_logic";
import Benefits from "./benefits";
import Brand from "./brand";
import Clients from "./clients";
import { Creative } from "./creative";
import { Images } from "./images";
import Stand from "./standout";
import Welcome from "./welcome";

const Home = () => {
  React.useEffect(() => {
    scroll_to_top_logic();
  }, []);
  return (
    <>
      <Welcome />
      <Creative />
      <Brand />
      <Stand />
      <Benefits />
      <Clients />
      <Images />
    </>
  );
};
export default Home;
