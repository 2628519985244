import React from "react";
const Benefits = () => {
  return (
    <section className="Benefits">
      <div className="Graphics">
        <div className="container_brand height40">
          <p className="Title_Text black center">Graphic Design</p>
          <p className="Text light_black">
            Great design makes you memorable. We deliver artwork that
            underscores your brand message and capture potential client's
            attention
          </p>
        </div>
      </div>
      <div className="Photgraphy">
        <div className="container_brand height40">
          <p className="Title_Text black center">Photgraphy</p>
          <p className="Text light_black">
            increase your credibility by getting the most stunning, high-quality
            photos that improves your basis images
          </p>
        </div>
      </div>
    </section>
  );
};
export default Benefits;
