import React from "react";

export const Creative = () => {
  return (
    <section className="Creative container">
      <div className="Creative_Title" aria-label="cratives">
        <p className="Title_Text white" aria-label="WE ARE CREATIVES">
          WE ARE CREATIVES
        </p>
      </div>
      <p className="Creative_Arrow">
        <span className="material-icons">south</span>
      </p>
    </section>
  );
};
