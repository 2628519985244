import React from "react";
import sankar_v from "../../../assests/image/WhatsApp Image 2021-09-26 at 10.34.34 AM.jpeg";
import sankar_p from "../../../assests/image/71566156_2599864796967029_8264404462468104364_n.jpg";
import uptopoint from "../../../assests/image/Uptopoint.png";
const clients = [
  {
    name: "Sankar P",
    post: "Propriter,Need Logistics",
    comment:
      "We put our trust in Vignesh Chari and he is creating us a beautiful site, making sure our need has met and the deadline is always right",
    pic: sankar_v,
  },
  {
    name: "Sankar V",
    post: "Operational Manager,Royal Security Services",
    comment:
      "Vignesh Chari enthusiasm coupled with his keen interest in our company success made it satisfying and enjoyable experience",
    pic: sankar_p,
  },
  {
    name: "uptopoint",
    post: "CEO",
    comment:
      "Incredible end results! Our sales increased over 200% when we worked with Vignesh Chari, highly recommended",
    pic: uptopoint,
  },
];
const Clients = () => {
  return (
    <section className="Clients container">
      <p className="Title_Text black">CLIENT TESTIMONIALS</p>
      <div className="Client_Wraper">
        {clients.map((client) => {
          return (
            <div
              className="Client_container Title_text black"
              key={client.name}
            >
              <img
                src={client.pic}
                alt={client.name}
                aria-label={client.name}
                className="Client_Image"
              ></img>
              <p className="Client_Comment">{client.comment}</p>
              <div className="Client_Information">
                <p className="Client_Name Title_text black">{client.name}</p>
                <p className="Client_Title Text light_black">{client.post}</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Clients;
