import Extraction from "../../../assests/image/dataextraction.png";
import LoginUi from "../../../assests/image/projects/Login2.png";
import blog from "../../../assests/image/projects/blogs-image.jpg";
import complaints from "../../../assests/image/projects/complaints.jpg"

const ProjectS = [
  {
    title:
      "Complaints Management System (Aug-2024 to Present)",
    image: complaints,
    text: "Developed a Web API for complaint management, facilitating the creation of tickets by the support team, which are then assigned to associates by the admin for prompt resolution of customer issues.",
    Technolgies: "ASP.NET Core,SQL",
  },
  {
    title:
      "Data Extraction and Image Analysis Application (Aug-2022 to Nov-2022)",
    image: Extraction,
    text: "Developed An Application for Extract data for Manhole process automation to Collect & Analyze data related to manhole maintanance and repairs process to improve decision making and improve accuracy in maintanance, once the data has been extracted, it can be stored in DB,Visualization of Diagrams will be in Autocad.",
    Technolgies: "Python,PostgreSQL",
  },
  {
    title: "Data Validation WorkBench (June-2023 to June-2024)",
    image: LoginUi,
    text: " Data Validation Workbench (Windows Application) is a powerful tool designed to streamline data validation processes and enhance data accuracy in Spreadsheets. It offers a comprehensive set of features and functionalities to assist users in validating and verifying data inputs, ensuring high-quality and error-free data.",
    Technolgies: "C#.NET,WPF,SQL",
  },
  {
    title: "Blogging Portal (Nov-2022 to Present)",
    image: blog,
    text: "This is the reactjs based personel blogging portal, Users can signup and post their own thoughts on this Portal https://blogging.vigneshchari.dev/blogs",
    Technolgies: "Python,React",
  },
];

const Project = () => {
  return (
    <section className="Why_Us container_restrict">
      <div className="Why_Us__Intro">
        <p className="Title_Text">
          Each Project represents a Unique challenge & an Opportunity to push
          boundaries.
        </p>
        <p className="Text">
          {" "}
          Discover the creative solutions that lie within.
        </p>
      </div>

      {ProjectS.map((project) => {
        return (
          <div className="Why_Us__Wrap" key={project.title}>
            <div className="Why_Us_Contaiiner" key={project.title}>
              <div className="Why_Us_Image">
                <img
                  src={project.image}
                  //data-src={project.image ?? img}
                  alt={project.title}
                  aria-label={project.title}
                  className="Why_Us_Images"
                />
              </div>
              <div className="Why_Us_Text">
                <p className="SubHeading_Text white_text">{project.title}</p>
                <p className="Sub_Text Text">{project.text}</p>
              </div>
              
            </div>
          </div>
        );
      })}
    </section>
  );
};
export default Project;
