import React from "react";
import Project from "./projects";

const Projects = () => {
  return (
    <div className="projects_container">
      <div className="projects_container_title">
        {/* <p>Projects</p> */}
        <div className="projects_container_title_text">
          Here you'll find a showcase of my diverse range of Projects, Each one
          Meticulously crafted to Deliver Unique Experiences and solve
          Real-world Challenges.
          Take a moment to explore the Projects I've had the Privilege to Work on.
        </div>
        </div>
        {/* <div className="Why_Us__Wrap">
            <div className="Why_Us_Contaiiner height70 ">
                <div className="Why_Us_Image">
                <img src alt="XLValidations" />
                </div>
            </div>
        </div> */}
        <Project/>
        
    </div>
  );
};

export default Projects;
