import React from "react";
import scroll_to_top_logic from "../../shared/scrolltotop_logic";
import AboutMe from "./about";
import Reasons from "./reasons";
import Whyus from "./whyus";
const About = () => {
  React.useEffect(() => {
    const option = {
      rootMargin: "0px 0px 0px 0px",
      threshold: 0,
    };
    const images = document.querySelectorAll(".Why_Us_Images");
    const observer = new IntersectionObserver((entries, observer) => {
      if (
        !entries.forEach((entry) => {
          if (!entry.isIntersecting) return;
          if (entry.isIntersecting) {
            entry.target.src = entry.target.getAttribute("data-src");
            observer.unobserve(entry.target);
          }
        })
      );
    }, option);
    images.forEach((image) => {
      observer.observe(image);
    });
    scroll_to_top_logic();
  }, []);
  return (
    <>
      <AboutMe />
      <Whyus />
      <Reasons />
    </>
  );
};

export default About;
