import React from "react";
const Stand = () => {
  return (
    <section className="standout">
      <div className="standout_image"></div>
      <div className="standout_text">
        <div className="container_brand">
          <p className="Title_Text black">Stand out to right audience</p>
          <p className="light_black Text">
            Using a collaborative formula of
            design,research,photographs,videos,we'll build and extend your brand
            in digital place
          </p>
        </div>
      </div>
    </section>
  );
};

export default Stand;
